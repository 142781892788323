/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ["admin", "editor"]
  return valid_map.indexOf(str.trim()) >= 0
}

export function validateAmount(rule, value, callback) {
  if (value === "") {
    callback(new Error("请填写价格"))
  } else if (parseFloat(value) <= 0 || isNaN(value)) {
    callback(new Error("价格必须是数字且大于等于0"))
  } else {
    callback()
  }
}
