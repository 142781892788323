<template>
  <div v-if="isExternal" :style="styleExternalIcon" class="svg-external-icon svg-icon" v-on="$listeners" />
  <svg v-else :class="svgClass" aria-hidden="true" v-on="$listeners" viewBox="0 0 20 20" height="20" width="20">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage
import { isExternal } from '@/utils/validate'

export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.iconClass)
    },
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        if (this.iconClass == 'menu_recharge') {
          return 'svg-icon custom_menu_recharge ' + this.className
        } else if (this.iconClass == 'menu_orders') {
          return 'svg-icon custom_menu_orders ' + this.className
        } else if (this.iconClass == 'menu_merchant') {
          return 'svg-icon custom_menu_merchant ' + this.className
        } else if (this.iconClass == 'menu_users') {
          return 'svg-icon custom_menu_users ' + this.className
        } else if (this.iconClass == 'menu_distribution') {
          return 'svg-icon custom_menu_distribution ' + this.className
        } else if (this.iconClass == 'menu_gonggao') {
          return 'svg-icon custom_menu_gonggao ' + this.className
        } else if (this.iconClass == 'mene_promotion') {
          return 'svg-icon custom_mene_promotion ' + this.className
        } else {
          return 'svg-icon' + this.className
        }
      } else {
        if (this.iconClass == 'menu_recharge') {
          return 'svg-icon custom_menu_recharge '
        } else if (this.iconClass == 'menu_orders') {
          return 'svg-icon custom_menu_orders '
        } else if (this.iconClass == 'menu_merchant') {
          return 'svg-icon custom_menu_merchant '
        } else if (this.iconClass == 'menu_users') {
          return 'svg-icon custom_menu_users '
        } else if (this.iconClass == 'menu_distribution') {
          return 'svg-icon custom_menu_distribution '
        } else if (this.iconClass == 'menu_gonggao') {
          return 'svg-icon custom_menu_gonggao '
        } else if (this.iconClass == 'mene_promotion') {
          return 'svg-icon custom_mene_promotion '
        } else {
          return 'svg-icon'
        }
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`
      }
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}

.custom_menu_recharge {
  font-size: 30px;
}

.custom_menu_orders {
  font-size: 18px;
}

.custom_menu_merchant {
  font-size: 22px;
}

.custom_menu_users {
  font-size: 20px;
}

.custom_menu_distribution,
.custom_menu_gonggao,
.custom_mene_promotion {
  font-size: 22px;
}
</style>
