<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>
<style>
	
	.el-table td.el-table__cell{
		border-color: #EAEEF3 !important; 
	}
	 .el-table--border .el-table__cell{
		border-color: #EAEEF3 !important; 
	}
</style>